import Vue from 'vue'
import Vuex from 'vuex'
import md5 from 'js-md5'
import request from '@/utils/request'
import TCCStore from './module/TCCStore'
import EnglishStore from './module/EnglishStore'
// import 
Vue.use(Vuex)


export default new Vuex.Store({
  // 提供唯一的公共数据源，所有共享的数据统一放到store的state进行储存，相似与data
  state: {
    isCollapse: false, // 控制菜单的展开与收起
    APIinfo: {}, // 请求需要的加密信息
    requestAPI: 'https://www.jlcoo.com/api',
    // requestAPI: 'http://192.168.0.110:8087/api',
    // menuList: [], // 一级菜单
    // secondMenuList: [], // 二级菜单数据
    // web端数据
    articleList: [], //web端文章列表
    nowTypeId: 0,
    NavList: [], //二级导航菜单
    activeName: '', //二级菜单激活名称
    firstName: '',
    secondName: '',
    imgPath: { // banner图对应的id和路径
      index: 19,
      list: '',
      text: {}
    },
  },
  // 更改 Vuex 的 store 中的状态的唯一方法是提交 mutation。Vuex 中的 mutation 非常类似于事件：每个 mutation 都有一个字符串的事件类型 (type)和一个回调函数 (handler)。这个回调函数就是我们实际进行状态更改的地方，并且它会接受 state 作为第一个参数
  mutations: {
    changeIsPhone(state,val) {
      state.isPhone = val
      // console.log(state.isPhone)
    },
    // 控制侧边栏的展开收起
    changeCollapse(state, val) {
      // console.log(state)
      state.isCollapse = !state.isCollapse;
    },
    // 接口设置的信息
    getApiInfo(state, val) {
      // console.log(val)
      let info = {};
      // console.log(info)
      info.ct = Date.now();
      // info.jpressAppId = val.value;
      // const secret = val.siteId;
      info.jpressAppId = 'admin';
      // console.log(info)
      const secret = '123456';
      const keys = Object.keys(info);
      // console.log(info)
      keys.sort();
      let str = '';
      for (const i of keys) {
        str += i + info[i];
      }
      str += secret;

      let sign = md5(window.encodeURI(str));
      info.sign = sign;
      // console.log(info)
      state.APIinfo = info;
      sessionStorage.setItem('APIinfo', JSON.stringify(info))
      // console.log(state.APIinfo)
    },
    // 改变banner数据
    changeImgPath(state, val) {
      state.imgPath.list = val;
      // state.imgPath.listText = {title: val.title, content: val.content};
    },
    changeBannerText(state, val) {
      console.log(val)
      state.imgPath.text = val;
    },
    // 二级导航菜单
    changeNav(state, val) {
      state.NavList = val;
      state.activeName = val[0].id
      state.secondName = val[0].title;
      // console.log(state.activeName)
    },
    // 修改二级激活名称
    changeActiveName(state, val) {
      state.activeName = val.id;
      state.secondName = val.title;
      // console.log(state.activeName)
    },
    changeFirstName(state, val) {
      // state.activeName = val.id;
      state.firstName = val.title;
      // console.log(state.activeName)
    },
  },
  // 进行异步操作
  actions: {
    // 获取接口设置的信息
    async getAPI(context) {
      // const res = await request({
      //   url: `/setting/findApiSettingById`,
      //   method: 'get',
      //   // params: 
      // });
      // console.log(res);
      context.commit('getApiInfo')
    },
    // 根据分类id获取文章列表
    async getArticleList(context) {
      if (context.state.nowTypeId !== 0) {
        let info = {
          categoryId: context.state.nowTypeId,
          status: 'normal',
          ...context.state.paging
        }
        // console.log(info)
        const res = await request({
          url: `/article/findlikeByType?ct=${context.state.APIinfo.ct}&jpressAppId=${context.state.APIinfo.jpressAppId}&sign=${context.state.APIinfo.sign}`,
          method: 'post',
          data: info
        });
        // console.log(res);
        if (res.code === 200) {
          // console.log(context.state.paging);
          let msg = {
            pageNum: context.state.paging.pageNum,
            total: res.data.total
          }
          // console.log(msg)
          context.commit('changeArticleList', res.data.list);
          context.commit('changePaging', msg);

          // this.articleList = res.data.list;
          // this.paging.total = res.data.total;
        }
      } else {
        context.commit('changeArticleList', []);
      }

    },
  },
  modules: {
    TCCStore,
    EnglishStore
  }
})