<template>
  <!-- 列表页 -->
  <div class="list">
    <div class="banner">
      <div class="promptText">
        <div class="webMain">
          <p class="Title">{{ $store.state.imgPath.text.title }}</p>
          <span class="border"></span>
          <p class="Text">{{ $store.state.imgPath.text.content }}</p>
        </div>
      </div>
      <img :src="$store.state.requestAPI + $store.state.imgPath.list" alt="">
    </div>
    <div class="topMenu">
      <div class="webMain">
        <ul>
          <li @click="choiceLi(item, index)" v-for="item, index in $store.state.NavList" :key="item.id">
            
            <span :class="item.id === $store.state.activeName ? 'active' : ''">{{ item.title }}</span>
            <!-- <p class="solid"
              v-show="index !== $store.state.NavList.length - 1 && item.id !== $store.state.activeName && (item.id + 1) !== $store.state.activeName">
            </p> -->
            <p class="solid"
              v-show="index !== $store.state.NavList.length - 1 && item.id !== $store.state.activeName && index !== nowIndex -1 ">
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="listContent">
      <div class="webMain">

        <div class="listbox">
          <div class="ele" v-for="item in articleList" :key="item.id" @click="toDetail(item)">
            <div class="left">
              <img v-if="item.thumbnail" :src="$store.state.requestAPI +  item.thumbnail" alt="">
              <img v-else src="../../assets/web/index/编组 33@2x.png" alt="">
            </div>
            <div class="center">
              <!-- <div class="eleTitle"> -->
              <p class="eleTitle p17">{{ item.title }}</p>
              <p class="eleContent p16">
                {{ item.summary }}
              </p>
            </div>
            <div class="right">
              <p class="time p16">{{ (item.created).slice(0, 10) }}</p>
              <span class="detail">Detail</span>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="paging.pageNum"
            :page-size="paging.pageSize" layout="prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="bgContent">
    </div>
  </div>
</template>

<script>
import { articleTypeListAPI } from '@/api/article/EnglishType'
import { articleListAPI } from '@/api/article/EnglishArticle'
import { menuListAPI } from '@/api/settings/EnglishMenu'
// import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
  data() {
    return {
      bannerPath: null,
      activeId: 1, //当前激活的菜单id
      activeName: '',//当前激活的菜单名称
      nowIndex: 0, // 当前激活菜单在文章分类对应的id
      paging: {
        pageNum: 1,
        pageSize: 6,
        total: 0
      },
      
      articleList: [],
      list: [],// 文章分类数据
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      console.log(val)
      this.paging.p = val;
      this.getArticleList();
    },
    //跳转至详情
    toDetail(val) {

      sessionStorage.setItem('path', this.$route.path)
      this.$router.push(`/english/detail/${val.id}`)
    },
    // 二级菜单跳转
    choiceLi(val,val2) {
      this.nowIndex = val2;
      this.$store.commit('changeActiveName', val);
      this.getArticleList();
      // this.$store.dispatch('getArticleType');
    },
    // 根据分类id获取文章列表
    async getArticleList(val) {
      // console.log(val)
      console.log(this.$store.state.activeName)
      let info = { pageSize: this.paging.pageSize, pageNum: this.paging.pageNum, status: 'normal', categoryId:this.$store.state.activeName }
      const res = await articleListAPI(info);
      console.log(res);
      if (res.code === 200) {
        this.articleList = res.data.list
        // this.$store.commit('changeArticleList', res.data.list);


        this.paging.total = res.data.total;
      }
    },
    
  },
  created() {
      this.getArticleList();
      
  },
  watch: {
    $route(to, from) {
      this.getArticleList();
    },
  }



}
</script>

<style lang="scss">
@keyframes shadow {
  from {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.22);
  }

  to {
    background: var(--second-color);
    box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.17);
  }
}

.listContent {
  padding: 10px 0;
 


  .listbox {
    min-height: 200px;
    margin-top: 70px;
    background-color: white;
    // z-index: 1;

    .ele {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 40px;
      box-sizing: border-box;
      width: 100%;
      height: 145px;
      background: #E8F2FF;
      margin-bottom: 25px;
      border-left: 10px solid var(--custom-color1);


      &:hover {
        animation-name: shadow;
        animation-duration: 0.5s;
        animation-fill-mode: both;

        .left {
          overflow: hidden;
          img {
            transform: scale(1.2);
            -ms-transform: scale(1.2);
          }
        }

        .center {
          p {
            color: #222222;
          }
        }

        .right {

          // text-align: right;
          .time {
            color: white;
          }

          .detail {
            display: inline-block;
          }
        }
      }

      .left {
        height: 100%;
        width: 258px;
        overflow: hidden;
        // box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          transition: all 0.6s;
          -ms-transition: all 0.8s;
        }

      }

      .center {
        width: calc(100% - 380px);
        // height: 100%;
        padding-left: 40px;
        box-sizing: border-box;

        .eleTitle {
          font-weight: 600;
          line-height: 35px;
          color: #222222;
          /*这里要显示的设置宽度*/
          width: 100%;
          overflow: hidden;
          /*溢出隐藏*/
          white-space: nowrap;
          /*溢出不换行*/
          text-overflow: ellipsis;
          /*文字超出宽度则显示ellipsis省略号*/

        }

        .eleContent {

          display: -webkit-box;
          line-height: 25px;
          /** 对象作为伸缩盒子模型显示 **/
          overflow: hidden;
          word-break: break-all;
          /* break-all(允许在单词内换行。)  对pc端可以不要*/
          text-overflow: ellipsis;
          /* 超出部分省略号 */
          -webkit-box-orient: vertical;
          /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 2;
          /** 显示的行数 **/
        }
      }

      .right {
        width: 220px;
        text-align: right;


        .time {
          width: 100%;
          // padding-right: 5px;
          // text-align: center;
          // margin: 0 auto;
          font-weight: 600;
          color: #525252;
        }

        .detail {

          display: none;
          // width: 100%;
          // float: right;
          // background-color: white;
          font-size: 17px;
          margin-top: 20px;
          cursor: pointer;
          width: 107px;
          height: 45px;
          line-height: 45px;
          background-color: white;
          color: #222;
          font-weight: 600;
          text-align: center;


          // display: none;
        }
      }
    }
  }

  .block {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss" scoped>
.list {
  position: relative;

  .topMenu {
    background-color: var(--custom-color1);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.43);

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0 5px;


      li {
        // margin-bottom: 10px;
        background-color: var(--custom-color1);
        box-sizing: border-box;
        width: 130px;
        // margin-right: 20px;
        height: 86px;
        // line-height: 86px;
        text-align: center;
        display: flex;
        align-items: center;
        // line-height: 86px;
        // padding: 0 6px;
        cursor: pointer;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 600;
          padding: 0 6px;
          // display: inline-block;
          height: 66px;
          width: 100%;
          font-size: 17px;
          // line-height: 100%;
          // background-color: bisque;
        }

        .solid {
          width: 2px;
          height: 25px;
          background: white;
        }

        .active {
          background: linear-gradient(180deg, #FFAB0C 0%, #FFCD6D 100%);
          // box-shadow: -6px 0px 9px 0px rgba(0, 0, 0, 0.3), 6px 0px 9px 0px rgba(0, 0, 0, 0.3);
          border-radius: 3px;
          color: black;
        }

      }
    }
  }

  @keyframes shadow {
    from {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.22);
    }

    to {
      background: var(--second-color);
      box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.17);
    }
  }

  ::v-deep .el-pager li.active {
    color: var(--second-color) !important;
  }

  .banner {
    height: 420px;

    img {
      height: 100%;
      width: 100%;
    }

    .promptText {
      width: 100%;
    }
  }


}
</style>