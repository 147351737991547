import request from '@/utils/request'
// 获取文章列表
// console.log(store.state.APIinfo)
import store from '@/store'
store.dispatch('getAPI');

var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// var info = {};
// setTimeout(() => {
//   info = store.state.APIinfo
// }, 100);
// console.log(info)

//获取文章列表
export function articleListAPI(data) {
    return request({
      url: `/article/findlikeByType?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}
//全局搜索文章列表
export function searchArticleListAPI(data) {
  return request({
    url: `/article/findGlobal?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}

// 获取文章详情
export function articleDetailAPI(params) {
  let newParam = {...params, ...info};
  return request({
    url: '/article/findArticleById',
    method: 'get',
    params: newParam
  });
}
// 新建文章
export function createArticleAPI(data) {
  console.log(data)
  return request({
    url: `/article/doCreate?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// 修改文章
export function updateArticleAPI(data) {
  return request({
    url: `/article/updateArticle?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}
// 删除文章
export function deleteArticleAPI(data) {
  return request({
    url: `/article/deleteArticleList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
    method: 'post',
    data
  });
}