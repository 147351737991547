<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  created() {
    
  }
}
</script>

<style lang="scss">
@import './assets/css/style.css';

#app {
  font-family: PingFangSC-Semibold, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
// .banner{
//   position: relative;
//   .bannerText{
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     z-index: 2;
//     p{
//       color: white;
//       font-weight: 600;
//     }
//     .pTitle{
//       font-size: 58px;

//     }
//   }
// }

.webMain {
  max-width: 1200px;
  margin: 0 auto;

  p {
    margin: 0;
    padding: 0px;
  }
}
.bgContent{
    position: absolute;
    bottom: 0px;
    height: 920px;
    width: 100%;
    background: url('./assets/web/bg.png');
    background-size: contain;
    z-index: -1;
  }
  .promptText {
    width: 100%;
    position: absolute;
    padding-top: 77px;
    p {
      font-weight: 600;
      color: #FFFFFF;
    }

    .Title {
      font-size: 50px;

      line-height: 81px;
      text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.36);
      padding-bottom: 24px;
      
    }

    

    .Subtitle {
      font-size: 36px;
      line-height: 56px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.41);
      margin-bottom: 14px;
    }
    .border{
      height: 3px;
      width: 283px;
      display: block;
      background-color: white;
    }

    .Text {
      
      padding-top: 18px;
      font-size: 28px;
      line-height: 45px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.41);
    }

  }

@media screen and (min-width:1000px) and (max-width:1200px){
  .webMain {
        max-width: 850px;
      }
}
@media screen and (max-width:1000px){
   .v-modal{
     z-index: 2 !important;
    }
  .webMain {
        max-width: 90%;
        margin: 0 auto;
      }
}

p {
  margin: 0;
  padding: 0px;
}

.p30 {
  font-size: 30px;
}

.p24 {
  font-size: 24px;
}
.p22 {
  font-size: 22px;
}
.p32 {
  font-size: 32px;
}
.p16 {
  font-size: 16px;
}
.p17 {
  font-size: 17px;
}
.p18 {
  font-size: 18px;
}
.span17 {
  font-size: 17px;
}

body {
  padding: 0px;
  margin: 0px;

  /* 统一字体大小配置 */
  .p1 {
    font-size: 18px;
  }

  .span1 {
    font-size: 18px;
  }

  .span2 {
    font-size: 16px;
  }

  .span3 {
    font-size: 14px;
  }
}

.el-table,
.tableContent {
  min-height: 500px;
}

.el-table th.gutter {
  display: table-cell !important;
}

.el-table .el-table__body .el-table__cell {
  height: 32px !important;
  padding: 0px !important;
  line-height: 32px !important;
  font-size: 14px;
}

.el-table th.el-table__cell {
  height: 35px !important;
  padding: 0px !important;
  line-height: 35px !important;
  font-size: 14px;
}

// // 取消数字输入框后的上下减
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

// 设置按钮区域的样式
.searchBtn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  // align-items: center;
  .el-button {
    margin-left: 20px !important;
    // margin-top: 20px;
    // padding-top: 20px;
  }

  .el-date-editor {
    margin-right: 20px;
  }

  .el-input,
  .el-select,
  .el-cascader,
  .el-date-picker {

    width: 150px !important;
    margin-right: 20px;
    // margin-bottom: 20px;
  }

  span {
    display: inline-block;
    margin-right: 10px;
    // line-height: 32px;
  }


}

.el-dialog__header {
  padding: 0px !important;
}

.block {
  text-align: center;
  // margin:0 auto;
  margin-top: 10px;

}
</style>
