import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// 获取附件列表
export function attachmentListAPI(data) {
  return request({
      url: `/attachment/attachmentList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}
export function deleteAttachmentAPI(data) {
  return request({
      url: `/attachment/deleteAttachment?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}