import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// 获取基础设置详情
export function updateBasicSettingsAPI(data) {
    return request({
        url: `/setting/addBasic?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
    });
}

// 获取菜单详情
export function basicSettingsAPI(params) {
    let newParam = {
        ...params,
        ...info
    };
    return request({
        url: '/setting/findBasicById',
        method: 'get',
        params: newParam
    });
}