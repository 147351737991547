import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import List from '@/views/web/List.vue'
import Detail from '@/views/web/Detail.vue'
import EnglishList from '@/views/EnglishWeb/List.vue'
import EnglishDetail from '@/views/EnglishWeb/Detail.vue'
import TCCList from '@/views/TCCWeb/List.vue'
import TCCDetail from '@/views/TCCWeb/Detail.vue'
Vue.use(VueRouter)
import {
  Message
} from 'element-ui';

const routes = [
  {
    path: '/',
    name: 'WebIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/web/Index.vue'),
    redirect: '/home',
    children: [
      {
      path: '/home',
      name: 'WebHome',
      component: () => import(/* webpackChunkName: "about" */ '../views/web/Home.vue'),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/web/ContactUs.vue'),
      },
      {
        path: 'list/:id',
        name: 'list',
        component: List,
      },
      {
        path: 'searchList',
        name: 'searchList',
        component:() =>  import('../views/web/SearchList.vue'),
      },
      {
        path: 'detail/:id',
        name: 'Detail',
        component: Detail,
      },
      
    ]
  },
  // 英语版页面
  {
    path: '/english',
    name: 'EnglishWebIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/EnglishWeb/Index.vue'),
    // redirect: '/index',
    children: [
      {
      path: 'index',
      name: 'EnglishWebHome',
      component: () => import(/* webpackChunkName: "about" */ '../views/EnglishWeb/Home.vue'),
      },
      {
        path: 'contact',
        name: 'contactEnglishEnglish',
        component: () => import(/* webpackChunkName: "about" */ '../views/EnglishWeb/ContactUs.vue'),
      },
      {
        path: 'list/:id',
        name: 'listEnglish',
        component: EnglishList,
      },
      {
        path: 'searchList',
        name: 'searchListEnglish',
        component:() =>  import('../views/EnglishWeb/SearchList.vue'),
      },
      {
        path: 'detail/:id',
        name: 'DetailEnglish',
        component: EnglishDetail,
      },
      
    ]
  },
  // 繁体版页面
  {
    path: '/tcc',
    name: 'TCCWebbIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/TCCWeb/Index.vue'),
    // redirect: '/index',
    children: [
      {
      path: 'index',
      name: 'TCCWebHome',
      component: () => import(/* webpackChunkName: "about" */ '../views/TCCWeb/Home.vue'),
      },
      {
        path: 'contact',
        name: 'contactTCC',
        component: () => import(/* webpackChunkName: "about" */ '../views/TCCWeb/ContactUs.vue'),
      },
      {
        path: 'list/:id',
        name: 'listTCC',
        component: TCCList,
      },
      {
        path: 'searchList',
        name: 'searchListTCC',
        component:() =>  import('../views/TCCWeb/SearchList.vue'),
      },
      {
        path: 'detail/:id',
        name: 'DetailTCC',
        component: TCCDetail,
      },
      
    ]
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    // redirect: '/'
  },
  {
    path: '/admin',
    name: 'admin',
    component: Home,
    beforeEnter: async (to, from, next) => {
      // 获取本地的 token
      const token = sessionStorage.token;
      if (token) {
        next();
        // }
      } else {
        Message({
          showClose: true,
          message: '你还未登录，请先登录！'
        });
        // alert("你还未登录，请先登录"); // 可以根据项目的 UI 框架来设置弹框提示
        next("/admin/login");
      }
    },
   children: [
    {
      path: 'updatePassword',
      name:'UpdatePassword',
      component: () => import(/* webpackChunkName: "about" */ '../views/user/UpdatePass.vue'),
     
    },
    // 中文版文章模块
    {
      path: 'article/list',
      name:'ArticleList',
      component: () => import(/* webpackChunkName: "about" */ '../views/article/Index.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章管理'}
        ],
        info: '可查看编辑文章'
      }
    },
    {
      path: 'article/write',
      name:'ArticleWrite',
      component: () => import(/* webpackChunkName: "about" */ '../views/article/Write.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '写文章'}
        ]
      }
    },
    {
      path: 'article/type',
      name:'ArticleType',
      component: () => import(/* webpackChunkName: "about" */ '../views/article/Type.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章分类'}
        ]
      }
    },
    {
      path: 'article/slug',
      name:'ArticleSlug',
      component: () => import(/* webpackChunkName: "about" */ '../views/article/Label.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章标签'}
        ]
      }
    },
    // 英语版文章模块
    {
      path: 'EnglishArticle/list',
      name:'EnglishArticleList',
      component: () => import(/* webpackChunkName: "about" */ '../views/EnglishArticle/Index.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章管理'}
        ],
        info: '可查看编辑文章'
      }
    },
    {
      path: 'EnglishArticle/write',
      name:'EnglishArticleWrite',
      component: () => import(/* webpackChunkName: "about" */ '../views/EnglishArticle/Write.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '写文章'}
        ]
      }
    },
    {
      path: 'EnglishArticle/type',
      name:'EnglishArticleType',
      component: () => import(/* webpackChunkName: "about" */ '../views/EnglishArticle/Type.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章分类'}
        ]
      }
    },
    {
      path: 'EnglishArticle/slug',
      name:'EnglishArticleSlug',
      component: () => import(/* webpackChunkName: "about" */ '../views/EnglishArticle/Label.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章标签'}
        ]
      }
    },
    // 繁体版文章模块
    {
      path: 'TCCArticle/list',
      name:'TCCArticleList',
      component: () => import(/* webpackChunkName: "about" */ '../views/TCCArticle/Index.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章管理'}
        ],
        info: '可查看编辑文章'
      }
    },
    {
      path: 'TCCArticle/write',
      name:'TCCArticleWrite',
      component: () => import(/* webpackChunkName: "about" */ '../views/TCCArticle/Write.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '写文章'}
        ]
      }
    },
    {
      path: 'TCCArticle/type',
      name:'TCCArticleType',
      component: () => import(/* webpackChunkName: "about" */ '../views/TCCArticle/Type.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章分类'}
        ]
      }
    },
    {
      path: 'TCCArticle/slug',
      name:'TCCArticleSlug',
      component: () => import(/* webpackChunkName: "about" */ '../views/TCCArticle/Label.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '文章'},
          {id: 3, name: '文章标签'}
        ]
      }
    },
    {
      path: 'attachment/list',
      name:"attachmentList",
      component: () => import('../views/attachment/Index.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '附件'},
          {id: 3, name: '附件列表'}
        ]
      }
    },
    {
      path: 'attachment/upload',
      name:"attachmentUpload",
      component: () => import('../views/attachment/Upload.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '附件'},
          {id: 3, name: '上传'}
        ]
      }
    },
    {
      path: 'attachment/classify',
      name:"attachmentClassify",
      component: () => import('../views/attachment/Classify.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '附件'},
          {id: 3, name: '分类管理'}
        ]
      }
    },
    {
      path: 'user/list',
      name:"userList",
      component: () => import('@/views/user/List.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '用户'},
          {id: 3, name: '用户管理'}
        ]
      }
    },
    {
      path: 'user/editUser',
      name:"userEditUser",
      component: () => import('@/views/user/EditUser.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '用户'},
          {id: 3, name: '编辑用户'}
        ]
      }
    },
    {
      path: 'settings/basic',
      name:"settingsBasic",
      component: () => import('@/views/settings/Basic.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '设置'},
          {id: 3, name: '基础设置'}
        ]
      }
    },
    // 中文菜单设置
    {
      path: 'settings/menu',
      name:"settingsMenu",
      component: () => import('@/views/settings/Menu.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '设置'},
          {id: 3, name: '菜单设置'}
        ]
      }
    },
     // 英文菜单设置
     {
      path: 'settings/EnglishMenu',
      name:"settingsEnglishMenu",
      component: () => import('@/views/settings/EnglishMenu.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '设置'},
          {id: 3, name: '菜单设置'}
        ]
      }
    },
     // 繁体菜单设置
     {
      path: 'settings/TCCmenu',
      name:"settingsTCCmenu",
      component: () => import('@/views/settings/TCCMenu.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '设置'},
          {id: 3, name: '菜单设置'}
        ]
      }
    },
    {
      path: 'settings/api',
      name:"settingsAPI",
      component: () => import('@/views/settings/ApiSetting.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '设置'},
          {id: 3, name: '接口设置'}
        ]
      }
    },
    {
      path: 'settings/SEO',
      name:"settingsSEO",
      component: () => import('@/views/settings/SEOSetting.vue'),
      meta: {
        breadcrumbList: [
          {id: 1 , name: '首页'},
          {id: 2, name: '设置'},
          {id: 3, name: 'SEO设置'}
        ]
      }
    },
   ]
  },
  
]

const router = new VueRouter({
  routes,
    // return 期望滚动到哪个的位置
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
           return savedPosition
      } else {
       // vue2.0  x  y  控制
       // vue3.0  left  top 控制
      return { x: 0, y: 0 }
   }
  }
})

export default router
