import Vue from 'vue'
import Vuex from 'vuex'
import md5 from 'js-md5'
import request from '@/utils/request'
// import 


export default ({
    namespaced: true,
  // 提供唯一的公共数据源，所有共享的数据统一放到store的state进行储存，相似与data
  state: {
    APIinfo: {}, // 请求需要的加密信息
    menuList: [], // 一级菜单
    secondMenuList: [], // 二级菜单数据
    // web端数据
    articleList: [], //web端文章列表
    nowTypeId: 0,
    activeId: 1, //当前激活的菜单id
    activeName: '', //当前激活的菜单名称
    // textList: [],
    imgPath: { // banner图对应的id和路径
      index: 19,
      list: '',
      listText: {}
    },
    paging: { // 分页数据
      pageNum: 1,
      pageSize: 7
    },
    total: 0, // 文章总数
  },
  // 更改 Vuex 的 store 中的状态的唯一方法是提交 mutation。Vuex 中的 mutation 非常类似于事件：每个 mutation 都有一个字符串的事件类型 (type)和一个回调函数 (handler)。这个回调函数就是我们实际进行状态更改的地方，并且它会接受 state 作为第一个参数
  mutations: {
    // 接口设置的信息
    getApiInfo(state, val) {
      // console.log(val)
      let info = {};
      // console.log(info)
      info.ct = Date.now();
      // info.jpressAppId = val.value;
      // const secret = val.siteId;
      info.jpressAppId = 'admin';
      // console.log(info)
      const secret = '123456';
      const keys = Object.keys(info);
      // console.log(info)
      keys.sort();
      let str = '';
      for (const i of keys) {
        str += i + info[i];
      }
      str += secret;

      let sign = md5(window.encodeURI(str));
      info.sign = sign;
      // console.log(info)
      state.APIinfo = info;
      // sessionStorage.setItem('APIinfo', JSON.stringify(info))
      // console.log(state.APIinfo)
    },
    // 修改一级菜单
    changeMenuList(state, val) {
      state.menuList = val;
    },
    // 修改二级菜单
    changeSecondList(state, val) {
      state.secondMenuList = val;
    },
    // 当前激活的id
    changeActiveId(state, val) {
      state.activeId = val;
    },
    // 当前激活的名称
    changeActiveName(state, val) {
      state.activeName = val;
    },
    // 当前激活的id
    changeNowTypeId(state, val) {
      state.nowTypeId = val;
    },
    // 文章列表
    changeArticleList(state, val) {
      state.articleList = val;
    },
    // 分页数据
    changePaging(state, val) {
      // console.log(val)
      state.paging.pageNum = val.pageNum;
      state.total = val.total
    },
    changeImgPath(state, val) {
      state.imgPath.list = val;
      // state.imgPath.listText = {title: val.title, content: val.content};
    },
    changeImgListText(state, val) {
      // console.log(val)
      // state.imgPath.list = val.path;
      state.imgPath.listText = {
        title: val.title,
        content: val.content
      };
    }
  },
  // 进行异步操作
  actions: {
    // 获取接口设置的信息
    async getAPI(context) {
      // const res = await request({
      //   url: `/setting/findApiSettingById`,
      //   method: 'get',
      //   // params: 
      // });
      // console.log(res);
      context.commit('getApiInfo')
    },
    // 获得二级导航菜单
    async getMenuList(context, path) {
      let arr = []
      let list = context.state.menuList;
      // console.log(list)
      list.forEach(item => {
        if (item.url === path) {
          arr = item.children;
        }
      })

      context.commit('changeSecondList', arr);
      // console.log(arr)
      if (arr && arr.length > 0) {
        context.commit('changeActiveId', arr[0].id);
        context.commit('changeActiveName', arr[0].text);
      }
      // console.log(arr[0])
      // console.log(context.state)
      // }
    },
    // 获取文章分类的数据
    async getArticleType(context) {
      // let info = {
      //   ...context.state.paging
      // }
      context.commit('getApiInfo')
      // console.log(context.state.APIinfo)
      let info = {
        pageSize: 10000,
        pageNum: 1,
        ...context.state.APIinfo
      }
      // console.log(info)
      const res = await request({
        url: `/EnglishArticle/typeList`,
        method: 'get',
        params: info
      });

      // console.log(res);
      if (res.code === 200) {
        context.dispatch('getTypeId', res.data.list);
        // this.getArticleList();
        // console.log(this.$store.state.nowTypeId)
        setTimeout(() => {
          context.dispatch('getArticleList')
        }, 100);
      }
    },
    // 获取二级菜单的id
    getTypeId(context, val) {
      // console.log(val)
      val.forEach(item => {
        // console.log(context.state.activeName)
        if (item.title === context.state.activeName) {
          // console.log(item.id)
          context.commit('changeNowTypeId', item.id)
          // this.nowTypeId = item.id;
          // console.log(context.state.nowTypeId)
        } else {
          if (item.children) {
            context.dispatch('getTypeId', item.children)
          }
        }
      })
    },
    // 根据分类id获取文章列表
    async getArticleList(context) {
      if (context.state.nowTypeId !== 0) {
        let info = {
          categoryId: context.state.nowTypeId,
          ...context.state.paging
        }
        // console.log(info)
        const res = await request({
          url: `/EnglishArticle/findList?ct=${context.state.APIinfo.ct}&jpressAppId=${context.state.APIinfo.jpressAppId}&sign=${context.state.APIinfo.sign}`,
          method: 'post',
          data: info
        });
        // console.log(res);
        if (res.code === 200) {
          // console.log(context.state.paging);
          let msg = {
            pageNum: context.state.paging.pageNum,
            total: res.data.total
          }
          // console.log(msg)
          context.commit('changeArticleList', res.data.list);
          context.commit('changePaging', msg);

          // this.articleList = res.data.list;
          // this.paging.total = res.data.total;
        }
      } else {
        context.commit('changeArticleList', []);
      }

    },
  },
  modules: {}
})