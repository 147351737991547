<template>
  <div class="detail">
    <!-- 详情 -->
    <div class="banner">
      <div class="promptText">
        <div class="webMain">
          <p class="Title">{{ $store.state.imgPath.text.title }}</p>
          <span class="border"></span>
          <p class="Text">{{ $store.state.imgPath.text.content }}</p>
        </div>
      </div>
      <img v-if="$store.state.imgPath.list" :src="$store.state.requestAPI + $store.state.imgPath.list" alt="">
      <img v-else src="../../assets/web/index/banner.png" alt="">
    </div>
    <div class="webMain">
      <div class="crumbs">
        <span>{{ $store.state.firstName }}</span> <span> > {{ $store.state.secondName }}</span>
      </div>
      <div class="titleTop">
        <p class="title p24">{{ article.title }}</p>
        <p class="time p16">{{ article.created }}</p>
      </div>
      <div class="articleContent">
        <p v-html="article.content"></p>
      </div>
      <!-- <div class="detailBottom">
        <div class="prev" v-if="prevList">
          <span>上一篇：</span><span @click="toDetail(prevList.id)">{{ prevList.title }}</span>
        </div>
        <div class="next" v-if="nextList">
          <span>下一篇：</span><span @click="toDetail(nextList.id)">{{ nextList.title }}</span>
        </div>
      </div> -->
      <div class="detailBtn">
        <span @click="backList">返回列表</span>
      </div>
    </div>
    <div class="bgContent">

    </div>
  </div>
</template>

<script>
import { articleDetailAPI } from '@/api/article/TCCArticle'
import { menuListAPI } from '@/api/settings/TCCMenu'
import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
  data() {
    return {
      activeName: '',
      secondName: '',
      prevList: {}, //前一篇文章
      nextList: {}, // 后一篇文章
      article: {
      },
      menuList: []
      // 文章详情
    }
  },
  methods: {
    // 获取文章详情
    async getDetail() {
      const res = await articleDetailAPI({ id: this.$route.params.id });
      console.log(res);
      if (res.code === 200) {
        this.article = res.data;
      }
    },
    toDetail(val) {
      this.$router.push(`/tcc/detail/${val}`);
      this.getDetail();
    },
    // 返回列表
    backList() {
      let path = sessionStorage.getItem('path');
      this.$router.push(path)
      // setTimeout(() => {
      //   location.reload();
      // }, 100);
    },
    // 获取菜单列表
    async getList() {
      let pathName = null;
      let info = { pageSize: 1000, pageNum: 1 }
      const res = await menuListAPI(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.menuList = res.data.list;
        this.$store.commit('changeMenuList', res.data.list);
        // console.log(this.$store.state.menuList)
        // 提取banner部分的内容
        res.data.list.forEach(item => {
          if (sessionStorage.getItem('path') === item.url) {
            pathName = item.text;
            this.$store.commit('changeImgListText', item)
          }
        })
        // console.log(pathName)
        this.getAttachmentList(pathName);
        // this.tableData = res.data.list;

      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '列表获取失败!'
        });
      }
    },
    // 获取附件列表
    async getAttachmentList(val) {
      // console.log(val)
      let info = { pageSize: 100000, pageNum: 1, categoryId: 0 }
      const res = await attachmentListAPI(info);
      // console.log(res);
      if (res.code === 200) {
        // this.imgList = res.data.list;
        let name = val + '.png'
        res.data.list.forEach(item => {
          // console.log(item.title)
          if (item.title === name) {
            // console.log(item)
            this.$store.commit('changeImgPath', item.path)
            // this.bannerPath = item.path
          }
        })
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '列表获取失败!'
        });
      }

    },
  },
  created() {
    // this.getList();
    // this.$store.commit('changeImgListText', item)

    // setTimeout(() => {

    //   let list = this.menuList
    //   // console.log(list)
    //   let nowIndex = 0;
    //   if ((list).length > 1) {
    //     list.forEach((item, index) => {
    //       // console.log(index)
    //       if (Number(item.id) === Number(this.$route.params.id)) {
    //         nowIndex = index
    //       }
    //     })
    //   }
    //   this.prevList = list[nowIndex - 1]
    //   this.nextList = list[nowIndex + 1]
    // }, 100);

    this.getDetail();
    // this.activeName = sessionStorage.getItem('firstName');
    // this.secondName = sessionStorage.getItem('secondName')
  }
}
</script>

<style lang="scss" scoped>
.detail {
  position: relative;

  .promptText {
    p {
      text-align: center;
    }

    span {
      margin: 0 auto;
    }
  }

  .banner {
    img {
      height: 420px;
      width: 100%;
    }
  }

  .webMain {
    padding: 40px;

    .crumbs {

      span {
        font-size: 17px;
        font-weight: 600;
        color: #222;

        &:first-child {
          color: var(--second-color);
        }
      }
    }

    .titleTop {
      margin-top: 60px;
      padding-bottom: 40px;
      text-align: center;
      border-bottom: 1px solid #BBB;

      .title {
        font-weight: 600;
        color: #222222;
        margin-bottom: 20px;
      }
    }

    .articleContent {
      min-height: 600px;
      padding: 20px;
      border-bottom: 1px solid #BBB;
      // width: 100%;
      ::v-deep p {
        img {
          max-width: 100% !important;
        }
      }
    }

    .detailBottom {
      display: flex;
      padding: 30px 0px;
      border-top: 1px solid #BBB;
      border-bottom: 1px solid #BBB;

      span {
        font-size: 17px;
        cursor: pointer;
      }

      .next {
        margin-left: 100px;
      }
    }

    .detailBtn {
      text-align: center;
      margin-top: 40px;

      span {
        cursor: pointer;
        font-size: 18px;
        display: inline-block;
        width: 200px;
        height: 50px;
        line-height: 50px;
        font-weight: 600;
        background: var(--second-color);
      }
    }
  }
}</style>