import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
export function menuListAPI(data) {
    return request({
        url: `/setting/findWebMenuList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
    });
}
// 获取菜单数据 供状态机使用
export function menuListAPI1(data, APIinfo) {
    return request({
        url: `/setting/findWebMenuList?ct=${APIinfo.ct}&jpressAppId=${APIinfo.jpressAppId}&sign=${APIinfo.sign}`,
        method: 'post',
        data
    });
}
// 获取菜单详情
export function detailMenuAPI(params) {
    let newParam = {
        ...params,
        ...info
    };
    return request({
        url: '/setting/findWebMenuById',
        method: 'get',
        params: newParam
    });
}

// 修改菜单
export function updateMenuAPI(data) {
    return request({
        url: `/setting/updateWebMenu?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
    });
}
// 新增菜单
export function addMenuAPI(data) {
    return request({
        url: `/setting/addWebMenu?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
    });
}
// 删除菜单
export function deleteMenuAPI(data) {
    return request({
        url: `/setting/deleteWebMenu?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
    });
}