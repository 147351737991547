// 当附件为繁体时转换附件名字
function toName(data) {
    let name = ''
    if(data === '首頁') {
        name = '首页'
    } else if(data === '企業服務(境內)') {
        name = '企业服务(境内)'
    }if(data === '海外服務') {
        name = '海外服务'
    }if(data === '稅務法律') {
        name = '税务法律'
    }if(data === '數位化與互聯網') {
    }if(data ==='數位化與互聯網') {
        name = '数字化与互联网'
    }if(data === '教育') {
        name = '教育'
    }if(data === '會議展覽') {
        name = '会议展览'
    }if(data === '成功案例') {
        name = '成功案例'
    }if(data === '關於我們') {
        name = '关于我们'
    }
    return name
}
function toEnglishName(data) {
    let name = ''
    if(data === 'home') {
        name = '首页'
    } else if(data === 'Enterprise Services (Domestic)') {
        name = '企业服务(境内)'
    }if(data === 'Overseas services') {
        name = '海外服务'
    }if(data === 'tax law') {
        name = '税务法律'
    }if(data ==='Digitalization & the Internet') {
        name = '数字化与互联网'
    }if(data === 'education') {
        name = '教育'
    }if(data === 'Conference Exhibition') {
        name = '会议展览'
    }if(data === 'success cases') {
        name = '成功案例'
    }if(data === 'about') {
        name = '关于我们'
    }
    return name
}
export default {toName, toEnglishName};