<template>
  <div class="AsideMenu">
    <!-- 侧边栏 -->
    <div class="topLogo">
          <!-- logo区域 -->
          <img  :src="this.$store.state.requestAPI + settingsInfo.logo" alt="">
          <span v-if="!$store.state.isCollapse" class="span3">{{ settingsInfo.name }}</span>
          <!-- <i class="el-icon-s-unfold" v-if="isCollapse" @click="openMenu"></i>
        <i class="el-icon-s-fold" v-else @click="openMenu"></i> -->
        </div>
        <el-menu class="el-menu-vertical-demo" :default-active="routePath" :router="true"  :collapse="$store.state.isCollapse"
       >
      <el-submenu v-for="item in menuList" :key="item.menuId" :index="item.url">
        <template slot="title">
            <i :class="item.icon"></i><span slot="title">{{ item.menuTitle }}</span>
            </template>
        
        <template v-if="item.children">
            
          <el-menu-item :index="ele.url" v-for="ele in item.children" :key="ele.menuId">
            <!-- <i class="el-icon-coordinate"></i> -->
            <span>{{ ele.menuTitle }}</span>
        </el-menu-item>
        </template>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { basicSettingsAPI } from '@/api/settings/basic'
export default {
    data() {
    return {
      settingsInfo: {},
      // isCollapse: false,
      menuList: [
        {menuId: 1, menuTitle: '文章', icon: 'el-icon-edit-outline', url: 'admin/article', children: [{menuId: 1, menuTitle: '文章管理', url: '/admin/article/list'}, {menuId: 2, menuTitle: '写文章', url: '/admin/article/write'}, {menuId: 3, menuTitle: '分类',  url: '/admin/article/type'}, {menuId:4, menuTitle: '标签',  url: '/admin/article/slug'}]},

        {menuId: 6, menuTitle: '英语文章', icon: 'el-icon-edit-outline', url: 'admin/EnglishArticle', children: [{menuId: 1, menuTitle: '文章管理', url: '/admin/EnglishArticle/list'}, {menuId: 2, menuTitle: '写文章', url: '/admin/EnglishArticle/write'}, {menuId: 3, menuTitle: '分类',  url: '/admin/EnglishArticle/type'}, {menuId:4, menuTitle: '标签',  url: '/admin/EnglishArticle/slug'}]},

        {menuId: 7, menuTitle: '繁体文章', icon: 'el-icon-edit-outline', url: 'admin/TCCArticle', children: [{menuId: 1, menuTitle: '文章管理', url: '/admin/TCCArticle/list'}, {menuId: 2, menuTitle: '写文章', url: '/admin/TCCArticle/write'}, {menuId: 3, menuTitle: '分类',  url: '/admin/TCCArticle/type'}, {menuId:4, menuTitle: '标签',  url: '/admin/TCCArticle/slug'}]},

        {menuId: 2, menuTitle: '附件',icon: 'el-icon-folder', url: '/admin/attachment',  children: [{menuId: 1, menuTitle: '附件列表',  url: '/admin/attachment/list'}, {menuId: 2, menuTitle: '上传',  url: '/admin/attachment/upload'}, {menuId: 3, menuTitle: '分类',  url: '/admin/attachment/classify'}]},

        {menuId: 4, menuTitle: '用户', icon: 'el-icon-unlock', url: 'admin/user', children: [{menuId: 1, menuTitle: '用户管理',  url: '/admin/user/list'},
         {menuId: 2, menuTitle: '编辑用户',  url: '/admin/user/editUser'}
        ]},

        {menuId: 5, menuTitle: '设置', icon: 'el-icon-setting', url: 'admin/settings', children: [{menuId:1, menuTitle: '基础设置',  url: '/admin/settings/basic'},{menuId: 2, menuTitle: '中文菜单',  url: '/admin/settings/menu'},
        {menuId:6, menuTitle: '英文菜单',  url: '/admin/settings/EnglishMenu'},
        {menuId: 5, menuTitle: '繁体菜单',  url: '/admin/settings/TCCmenu'},
         {menuId:3 , menuTitle: '接口设置',  url: '/admin/settings/api'}, {menuId:4 , menuTitle: 'SEO设置',  url: '/admin/settings/SEO'}]},
        
      ]
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
     // 获取设置详情
     async getSettingsDetail() {
      const res = await basicSettingsAPI();
      // console.log(res);
      if (res.code === 200) {
        this.settingsInfo = res.data;
      }
    },

    // openMenu() {
    //     this.isCollapse = !this.isCollapse;
    // }
  },
  computed:{
    routePath() {
        // console.log(typeof(this.$route.path))
        return this.$route.path
    },


  },
  created() {
    this.getSettingsDetail();
    this.menuList = JSON.parse(sessionStorage.getItem('menus'));
    // console.log(this.menuList)
  }
}
</script>

<style lang="scss" scoped>
.AsideMenu{
    .topLogo{
      position: sticky;
      top: 0px;
      z-index: 1;
        padding-left: 20px;
        box-sizing: border-box;
        height: 50px;
        width: 100%;
        background-color: white;
        // color: w;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        // line-height: ;
        align-items: center;
        img{
            // width: 30px;
            height: 30px;
        }
        span{
            margin-left: 10px;
            display: inline-block;
            color: var(--custom-color);
            
        }
    }
}
.el-submenu .el-menu-item:hover{
    background-color: var(--white-color);
    i,span{
        color: var(--custom-color) !important;
    }
}
::v-deep .el-menu{
    background-color: var(--custom-color) !important;
    li, span, i{
        color: var(--white-color);
    }
    .el-submenu__title:hover {
        background-color: var(--white-color);
        i, span{
            color: var(--custom-color);
        }
        
    }
    .el-menu-item.is-active{
        background-color: var(--white-color);
        color: var(--custom-color);
        span{
            color: var(--custom-color);
        }
    }
    
    
   
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    background: var(--custom-color);
    width: 200px;
    min-height: 400px;
    
  }

</style>
