import request from '@/utils/request'
// console.log(store.state.APIinfo)
import store from '@/store'
store.dispatch('getAPI');
// var info = {};
// if(store.state.APIinfo.ct !== null) {
//   info = store.state.APIinfo;
// }
// if(sessionStorage.getItem('APIinfo')) {
//   info =  JSON.parse(sessionStorage.getItem('APIinfo'))
// }
// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// console.log( store.state.APIinfo)
// console.log(JSON.parse(sessionStorage.getItem('APIinfo')));
// setTimeout(() => {
//   console.log( store.state.APIinfo)
//   console.log(JSON.parse(sessionStorage.getItem('APIinfo')));
// }, 100)
// 获取文章分类
export function articleTypeListAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/category/list',
      method: 'get',
      params: newParam
    });
  }
  // 添加文章分类
export function addArticleTypeAPI(data) {
    return request({
      url: `/category/addType?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data: data
    });
  }
  // 文章分类详情
  export function articleTypeDetailAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/category/findTypeById',
      method: 'get',
      params: newParam
    });
}
 // 修改文章分类
 export function updateArticleTypeAPI(data) {
    return request({
      url: `/category/doUpdate?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data: data
    });
  }
   // 删除文章分类
 export function deleteArticleTypeAPI(data) {
    return request({
      url: `/category/deleteTypeList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data: data
    });
  }